
import { defineComponent, ref, onMounted } from "vue";
import { ApiBase } from "@/core/api";
import { useI18n } from "vue-i18n";

interface Filter {
  name: string;
}

export default defineComponent({
  name: "social-connections-dropdown",
  components: {},
  emits: ["submit-filter", "reset-filter"],
  setup(props, { emit }) {
    const formRef = ref<null | HTMLFormElement>(null);
    const data = ref<Filter>({
      name: "",
    });
    const { t } = useI18n();

    const options = ref({
      type: [],
    });
    const getDropdownOptions = async () => {
      const { data } = await ApiBase.getTaggingData({
        short_key: "global_social_connection_type",
      });
      if (data.code == 0) {
        options.value.type = data.data.items;
      }
    };

    const submit = () => {
      emit("submit-filter", data.value);
    };

    const handleReset = () => {
      formRef.value?.resetFields();
      emit("reset-filter");
    };

    onMounted(() => {
      // getCountryOptions();
      getDropdownOptions();
    });
    return {
      data,
      formRef,
      t,
      submit,
      handleReset,
      options,
    };
  },
});
